import { Route, RouteConfig } from 'vue-router'
import { camperStudent } from '@/helpers/instanceNamingConvention'
import { permissionSubjects } from '@/libs/acl/constants'

const admin: RouteConfig[] = [
  {
    path: '/admin/users',
    name: 'admin-all-users',
    component: () => import('@/views/admin/users/users-list/UsersList.vue'),
    meta: {
      requiresSchoolAndProgram: false,
      resource: permissionSubjects.User,
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/admins',
    name: 'admin-users',
    component: () => import('@/views/admin/admins/admins-list/AdminsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Admins',
      breadcrumb: [
        {
          text: 'Admins',
          active: true,
        },
      ],
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/user-add',
    name: 'admin-user-add',
    component: () => import('@/views/admin/users/user-add/UserAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'create',
      resource: permissionSubjects.User,
      pageTitle: 'Admin Creation',
      breadcrumb: [
        {
          text: 'Admins',
          active: true,
        },
      ],
      navActiveLink: 'admin-users',
    },
  },
  {
    path: '/admin/user-profile/:id',
    name: 'admin-user-profile',
    component: () => import('@/views/admin/users/user-profile/UserProfile.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.User,
      pageTitle: 'Admins',
      breadcrumb: [
        {
          text: 'Admins',
          active: true,
        },
      ],
      navActiveLink: 'admin-users',
    },
  },
  {
    path: '/admin/admin-profile/:id',
    name: 'admin-profile',
    component: () => import('@/views/admin/admins/admin-profile/AdminProfile.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.User,
      pageTitle: 'Admin Info',
      navActiveLink: 'admin-users',
    },
  },
  {
    path: `/admin/${camperStudent}s`,
    name: 'admin-students',
    component: () => import('@/views/admin/students/students-list/StudentsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Child,
      pageTitle: `${camperStudent}s`,
    },
  },
  {
    path: `/admin/${camperStudent}-profile/:id`,
    name: 'admin-student-profile',
    component: () => import('@/views/admin/students/student-profile/StudentsProfile.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      raction: 'read',
      resource: permissionSubjects.Child,
      pageTitle: `${camperStudent} Profile`,
      navActiveLink: 'admin-students',
    },
  },
  {
    path: '/admin/parents',
    name: 'admin-parents',
    component: () => import('@/views/admin/parents/parents-list/ParentsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Parent,
      pageTitle: 'Parents',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/import-parents',
    name: 'admin-import-parents',
    component: () => import('@/views/admin/parents/parents-import/ParentsImport.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Import parents',
      navActiveLink: 'admin-parents',
    },
  },
  {
    path: '/admin/parent-profile/:id',
    name: 'admin-parent-profile',
    component: () => import('@/views/admin/parents/parent-profile/ParentsProfile.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Parent,
      navActiveLink: 'admin-parents',
    },
  },
  {
    path: '/admin/staff',
    name: 'admin-staff',
    component: () => import('@/views/admin/staff/staff-list/StaffList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Staff,
      pageTitle: 'Staff',
      breadcrumb: [
        {
          text: 'Staff',
          active: true,
        },
      ],
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/staff-profile/:id',
    name: 'admin-staff-profile',
    component: () => import('@/views/admin/staff/staff-profile/StaffProfile.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Staff,
      pageTitle: 'Staff',
      navActiveLink: 'admin-staff',
    },
  },
  {
    path: '/admin/staff-program-profile/:id/:program/:application',
    name: 'admin-staff-program-profile',
    component: () => import('@/views/admin/staff/staff-program-profile/StaffProfile.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: 'Staff',
      pageTitle: 'Staff',
    },
  },
  {
    path: '/admin/programs',
    name: 'admin-program',
    component: () => import('@/views/admin/program/program-list/ProgramList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Program,
      pageTitle: 'Programs',
    },
  },
  {
    path: '/admin/program-add',
    name: 'admin-program-add',
    component: () => import('@/views/admin/program/program-add/ProgramAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Program,
      pageTitle: 'Programs',
      navActiveLink: 'admin-program-edit',
    },
  },
  {
    path: '/admin/program-edit/:id',
    name: 'admin-program-edit',
    component: () => import('@/views/admin/program/program-edit/ProgramEdit.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'update',
      resource: [
        permissionSubjects.Program,
        permissionSubjects.GeneralProgramSettings,
        permissionSubjects.RegistrationChildProgramSettings,
        permissionSubjects.RegistrationStaffProgramSettings,
        permissionSubjects.ProgramTasks,
        permissionSubjects.ProgramFees,
        permissionSubjects.ProgramWallets,
        permissionSubjects.ProgramTags,
      ],
      pageTitle: 'Programs',
    },
  },
  {
    path: '/admin/applications/:type',
    name: 'admin-applications',
    component: () => import('@/views/admin/applications/applications-list/ApplicationsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: [
        permissionSubjects.ChildApplication,
        permissionSubjects.StaffApplication,
      ],
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/application-add/:type/:id',
    name: 'admin-application-add',
    component: () => import('@/views/admin/applications/application-add/ApplicationAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Applications',
      navActiveLink: 'admin-applications',
    },
  },
  {
    path: '/admin/application-view/:type/:id',
    name: 'admin-application-view',
    component: () => import('@/views/admin/applications/application-view/ApplicationView.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Applications',
      navActiveLink: 'admin-applications',
    },
  },
  {
    path: '/admin/import-applications',
    name: 'admin-import-applications',
    component: () => import('@/views/admin/applications/applications-import/ApplicationsImport.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Import Applications',
      navActiveLink: 'admin-applications',
    },
  },
  {
    path: '/admin/new-application-edit/:type/:id',
    name: 'admin-application-edit',
    component: () => import('@/views/admin/applications/new-application-edit/ApplicationEdit.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: [
        permissionSubjects.ChildApplication,
        permissionSubjects.StaffApplication,
      ],
    },
  },
  {
    path: '/admin/groups',
    name: 'admin-groups',
    component: () => import('@/views/admin/group/group-list/GroupList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Group,
      pageTitle: 'Groups',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/groups-detail/:id',
    name: 'groups-detail',
    component: () => import('@/views/admin/group/group-detail/GroupDetail.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Group,
      pageTitle: 'Groups',
      navActiveLink: 'admin-groups',
    },
  },
  {
    path: '/admin/group-types',
    name: 'admin-group-types',
    component: () => import('@/views/admin/group-types/group-types-list/GroupTypesList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.GroupType,
      pageTitle: 'Group Types',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/form-folders',
    name: 'admin-form-folders',
    component: () => import('@/views/admin/forms/form-folders/FormFolders.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: 'Form',
      pageTitle: 'Forms',
    },
  },
  {
    path: '/admin/form-folder-detail/:id',
    name: 'admin-form-folder-detail',
    component: () => import('@/views/admin/forms/form-folders/FormFolderDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.FormFolder,
      navActiveLink: 'admin-form-folders',
    },
  },
  {
    path: '/admin/forms',
    name: 'admin-forms',
    component: () => import('@/views/admin/forms/form-list/FormList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Form,
      pageTitle: 'Forms',
    },
  },
  {
    path: '/admin/form-add',
    name: 'admin-form-add',
    component: () => import('@/views/admin/forms/form-add/FormAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'create',
      resource: permissionSubjects.Form,
      pageTitle: 'Forms',
      navActiveLink: 'admin-form-folders',
    },
  },
  {
    path: '/admin/form-edit/:id',
    name: 'admin-form-edit',
    component: () => import('@/views/admin/forms/form-edit/FormEdit.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Form,
      pageTitle: 'Forms',
      navActiveLink: 'admin-form-folders',
    },
  },
  {
    path: '/admin/family-form-folders',
    name: 'admin-family-form-folders',
    component: () => import('@/views/admin/forms/family-form-folders/FamilyFormFolders.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: 'Form',
      pageTitle: 'Family Forms',
    },
  },
  {
    path: '/admin/family-form-folder-detail/:id',
    name: 'admin-family-form-folder-detail',
    component: () => import('@/views/admin/forms/family-form-folders/FamilyFormFolderDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.FormFolder,
      navActiveLink: 'admin-family-form-folders',
    },
  },
  {
    path: '/admin/family-form-edit/:id',
    name: 'admin-family-form-edit',
    component: () => import('@/views/admin/forms/family-form-edit/FamilyFormEdit.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Form,
      pageTitle: 'Forms',
      navActiveLink: 'admin-family-form-folders',
    },
  },
  {
    path: '/admin/blog',
    name: 'admin-cms',
    component: () => import('@/views/admin/blog/blog-list/BlogList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'CMS',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/blog-add',
    name: 'admin-blog-add',
    component: () => import('@/views/admin/blog/blog-add/BlogAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'CMS',
      navActiveLink: 'admin-cms',
    },
  },
  {
    path: '/admin/blog-edit/:id',
    name: 'admin-cms-edit',
    component: () => import('@/views/admin/blog/blog-edit/BlogItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'CMS',
      navActiveLink: 'admin-cms',
    },
  },
  {
    path: '/admin/pages',
    name: 'admin-cms-pages',
    component: () => import('@/views/admin/pages/pages-list/PagesList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Pages',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/page-add',
    name: 'admin-page-add',
    component: () => import('@/views/admin/pages/pages-add/PageAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Pages',
      navActiveLink: 'admin-cms-pages',
    },
  },
  {
    path: '/admin/page-edit/:id',
    name: 'admin-page-edit',
    component: () => import('@/views/admin/pages/pages-edit/PageEdit.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Pages',
      navActiveLink: 'admin-cms-pages',
    },
  },
  {
    path: '/admin/email-folders',
    name: 'admin-email-templates-folders',
    component: () => import('@/views/admin/emails/email-folders/EmailFolders.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Email Templates',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/email-add',
    name: 'admin-email-add',
    component: () => import('@/views/admin/emails/email-add/EmailAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'create',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Email',
      navActiveLink: 'admin-email-templates-folders',
    },
  },
  {
    path: '/admin/email-edit/:id',
    name: 'admin-email-edit',
    component: () => import('@/views/admin/emails/email-edit/EmailItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Email',
      navActiveLink: 'admin-email-templates-folders',
    },
  },
  {
    path: '/admin/mass-communication',
    name: 'admin-mass-communication',
    component: () => import('@/views/admin/communication/communication-add/CommunicationAdd.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'send',
      resource: [
        permissionSubjects.BulkSMSCommunication,
        permissionSubjects.BulkEmailCommunication,
      ],
      pageTitle: 'Communicator',
    },
  },
  {
    path: '/admin/tasks',
    name: 'admin-tasks',
    component: () => import('@/views/admin/tasks/task-list/TaskList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.Tasks,
      pageTitle: 'Tasks',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/application-tasks/:taskId/:taskName',
    name: 'application-tasks',
    component: () => import('@/views/admin/tasks/application-task-list/ApplicationTaskList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      specialTitle: (route: Route) => (`Task ${route.params.taskName}`),
      navActiveLink: 'admin-tasks',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/tasks/:id',
    name: 'admin-task-edit',
    component: () => import('@/views/admin/tasks/task-edit/TaskEdit.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      pageTitle: 'Tasks',
      navActiveLink: 'admin-tasks',
    },
  },
  {
    path: '/admin/settings-communication',
    name: 'admin-settings-communication',
    component: () => import('@/views/admin/settings-communication/settings-communication-list/SettingsCommunicationList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailOnEvent,
      pageTitle: 'Communication',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/letters',
    name: 'admin-letter-templates',
    component: () => import('@/views/admin/letters/letters-list/LettersList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Letter Templates',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/letter-add',
    name: 'admin-letter-add',
    component: () => import('@/views/admin/letters/letter-template-item/LetterTemplateItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'create',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Letter Template',
      navActiveLink: 'admin-letter-templates',
    },
  },
  {
    path: '/admin/letter-edit/:id',
    name: 'admin-letter-edit',
    component: () => import('@/views/admin/letters/letter-template-item/LetterTemplateItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Letter Template',
      navActiveLink: 'admin-letter-templates',
    },
  },
  {
    path: '/admin/reports-folders',
    name: 'admin-reports-folders',
    component: () => import('@/views/admin/reports-v2/reports/reports-folders/ReportsFolders.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Report,
      pageTitle: 'Reports List',
    },
  },
  {
    path: '/admin/report-details',
    name: 'admin-report-details',
    component: () => import('@/views/admin/reports-v2/reports/report-details/ReportDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Report,
      navActiveLink: 'admin-reports-folders',
    },
  },
  // {
  //   path: '/admin/reports-view-list',
  //   name: 'admin-reports-view-list',
  //   component: () => import('@/views/admin/reports-v2/reports-view/reports-view-list/ReportsViewList.vue'),
  //   meta: {
  //     pageTitle: 'Report Types',
  //   },
  //   props: route => ({ query: route.query }),
  // },
  // {
  //   path: '/admin/report-view',
  //   name: 'admin-report-view',
  //   component: () => import('@/views/admin/reports-v2/reports-view/reports-view-detail/ReportViewDetail.vue'),
  //   meta: {
  //     pageTitle: 'Report View',
  //     navActiveLink: 'admin-reports-view-list',
  //   },
  // },
  {
    path: '/admin/fafsa-reports',
    name: 'admin-fafsa-reports',
    component: () => import('@/views/admin/fafsa-reports/fafsa-reports-list/FafsaReportsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.FinancialReport,
      pageTitle: 'Tuition Cards list',
      navActiveLink: 'admin-fafsa-reports',
    },
  },
  {
    path: '/admin/fafsa-report-details',
    name: 'admin-fafsa-report-details',
    component: () => import('@/views/admin/fafsa-reports/fafsa-report-details/FafsaReportDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.FinancialReport,
      navActiveLink: 'admin-fafsa-reports',
    },
  },
  {
    path: '/admin/email-audit',
    name: 'admin-email-audit',
    component: () => import('@/views/admin/email-audit/email-audit-list/EmailAuditList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailAudit,
      pageTitle: 'Email Audit',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/email-audit-detail/:id',
    name: 'admin-email-audit-detail',
    component: () => import('@/views/admin/email-audit/email-audit-detail/EmailAuditDetail.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailAudit,
      pageTitle: 'Email Audit',
      navActiveLink: 'admin-email-audit',
    },
  },
  {
    path: '/admin/transactions',
    name: 'admin-transactions',
    component: () => import('@/views/admin/transactions-v2/transactions-list/TransactionsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.FinancialReport,
      pageTitle: 'All Transactions',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/program-transactions',
    name: 'admin-program-transactions',
    component: () => import('@/views/admin/transactions-v2/transactions-list/TransactionsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Transaction,
      pageTitle: 'Program Transactions',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/payment-contracts',
    name: 'admin-payment-contracts',
    component: () => import('@/views/admin/payment-contracts/payment-contracts-list/PaymentContractsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.PaymentContract,
      pageTitle: 'Payment Contracts Setup',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/payment-contract',
    name: 'admin-payment-contract-details',
    component: () => import('@/views/admin/payment-contracts/payment-contract/PaymentContract.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.PaymentContract,
      navActiveLink: 'admin-payment-contracts',
    },
  },
  {
    path: '/admin/scheduled-emails',
    name: 'admin-scheduled-emails',
    component: () => import('@/views/admin/scheduled-emails/ScheduledEmailsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.ScheduledEmails,
      pageTitle: 'Scheduled Emails',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/program-users-contracts',
    name: 'admin-program-users-contracts',
    component: () => import('@/views/admin/program-users-contracts/ProgramUsersContractsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.PaymentContract,
      pageTitle: 'Payments Contracts Users',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/settings-dashboard',
    name: 'admin-settings-dashboard',
    component: () => import('@/views/admin/settings-dashboard/SettingsDashboard.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
    },
  },
  {
    path: '/admin/dashboard/',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/settings-dashboard/SettingsDashboard.vue'),
    meta: {
      requiresSchoolAndProgram: true,
    },
  },
  {
    path: '/admin/verified-emails',
    name: 'admin-verified-emails',
    component: () => import('@/views/admin/verified-emails/VerifiedEmailsList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/tag-scanner',
    name: 'admin-tag-scanner',
    meta: {
      requiresSchoolAndProgram: true,
      action: 'scan',
      resource: permissionSubjects.Tags,
      pageTitle: 'Tag Scanner',
    },
    component: () => import('@/views/admin/tag-scanner/TagScanner.vue'),
  },
  {
    path: '/admin/tag-view',
    name: 'admin-tag-view',
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Tags,
      pageTitle: 'Tag View',
    },
    component: () => import('@/views/admin/tag-view/TagView.vue'),
  },
  {
    path: '/admin/group-list-templates',
    name: 'admin-group-list-templates',
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Group List Templates',
    },
    component: () => import('@/views/admin/group-list-templates/group-list-templates-list/GroupListTemplatesList.vue'),
  },
  {
    path: '/admin/group-list-template/',
    name: 'admin-group-list-template-item',
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Group List Template',
      navActiveLink: 'admin-group-list-templates',
    },
    component: () => import('@/views/admin/group-list-templates/group-list-template-item/GroupListTemplateItem.vue'),
  },
  {
    path: '/admin/sms-templates',
    name: 'admin-sms-templates',
    component: () => import('@/views/admin/sms-templates/templates-list/SmsTemplatesList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.SMSTemplate,
      pageTitle: 'Sms Templates',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/sms-template-add',
    name: 'admin-sms-template-add',
    component: () => import('@/views/admin/sms-templates/sms-template-item/SmsTemplateItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'create',
      resource: permissionSubjects.SMSTemplate,
      pageTitle: 'SMS Template',
      navActiveLink: 'admin-sms-templates',
    },
  },
  {
    path: '/admin/sms-template-edit/:id',
    name: 'admin-sms-template-edit',
    component: () => import('@/views/admin/sms-templates/sms-template-item/SmsTemplateItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.SMSTemplate,
      pageTitle: 'SMS Template',
      navActiveLink: 'admin-sms-templates',
    },
  },
  {
    path: '/admin/tuition-card-templates',
    name: 'admin-tuition-card-templates',
    component: () => import('@/views/admin/tuition-card-templates/templates-list/TuitionCardTemplatesList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Tuition Card Templates',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/tuition-card-template-add',
    name: 'admin-tuition-card-template-add',
    component: () => import('@/views/admin/tuition-card-templates/tuition-card-template-item/TuitionCardTemplateItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'create',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'New Tuition Card Template',
      navActiveLink: 'admin-tuition-card-templates',
    },
  },
  {
    path: '/admin/tuition-card-template-edit/:id',
    name: 'admin-tuition-card-template-edit',
    component: () => import('@/views/admin/tuition-card-templates/tuition-card-template-item/TuitionCardTemplateItem.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.EmailTemplate,
      pageTitle: 'Tuition Card Template',
      navActiveLink: 'admin-tuition-card-templates',
    },
  },
  {
    path: '/admin/sms-audit',
    name: 'admin-sms-audit',
    component: () => import('@/views/admin/sms-audit/sms-audit-list/SmsAuditList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.SMSAudit,
      pageTitle: 'SMS Audit',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/sms-audit-detail/:id',
    name: 'admin-sms-audit-detail',
    component: () => import('@/views/admin/sms-audit/sms-audit-detail/SmsAuditDetail.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.SMSAudit,
      pageTitle: 'SMS Audit',
      navActiveLink: 'admin-sms-audit',
    },
  },
  {
    path: '/admin/group-wizard-list',
    name: 'admin-group-wizard-list',
    component: () => import('@/views/admin/group/group-assign-wizard/group-wizard-list/GroupWizardList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'manage',
      resource: permissionSubjects.GroupWizard,
      pageTitle: 'Group Wizards',
      navActiveLink: 'admin-groups',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/group-wizard-add',
    name: 'admin-group-wizard-add',
    component: () => import('@/views/admin/group/group-assign-wizard/kanban-view/KanbanViewV2.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'manage',
      resource: permissionSubjects.GroupWizard,
      pageTitle: 'Group Wizard',
      navActiveLink: 'admin-groups',
    },
  },
  {
    path: '/admin/group-wizard-edit/:id',
    name: 'admin-group-wizard-edit',
    component: () => import('@/views/admin/group/group-assign-wizard/kanban-view/KanbanViewV2.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'manage',
      resource: permissionSubjects.GroupWizard,
      pageTitle: 'Group Wizard',
      navActiveLink: 'admin-groups',
    },
  },
  {
    path: '/admin/admin-academics',
    name: 'admin-academics',
    component: () => import('@/views/admin/academics/classes-list/ClassesList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Academics,
      pageTitle: 'Classes',
    },
  },
  {
    path: '/admin/admin-academics/class/:id',
    name: 'admin-class-details',
    component: () => import('@/views/admin/academics/class-details/ClassDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Academics,
      navActiveLink: 'admin-academics',
    },
  },
  {
    path: '/admin/admin-academics/class/:id/:subjectId',
    name: 'admin-subject-details',
    component: () => import('@/views/admin/academics/subject-details/SubjectDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Academics,
      navActiveLink: 'admin-academics',
    },
  },
  {
    path: '/admin/admin-grade-mapping',
    name: 'admin-grade-mapping',
    component: () => import('@/views/admin/academics/grade-mapping/GradeMapping.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'read',
      resource: permissionSubjects.Academics,
      pageTitle: 'Grade mapping',
    },
  },
  {
    path: '/admin/admin-file-folders',
    name: 'admin-file-folders',
    component: () => import('@/views/admin/file-folders/file-folders-list/FileFoldersList.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'update',
      resource: permissionSubjects.GeneralProgramSettings,
      pageTitle: 'Files',
    },
  },
  {
    path: '/admin/admin-file-folders/:name/:label',
    name: 'admin-file-folder-details',
    component: () => import('@/views/admin/file-folders/file-folder-details/FileFolderDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'update',
      resource: permissionSubjects.GeneralProgramSettings,
      navActiveLink: 'admin-file-folders',
    },
  },
  {
    path: '/admin/admin-programs-settings',
    name: 'admin-programs-settings',
    component: () => import('@/views/admin/settings-programs/SettingsPrograms.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'update',
      resource: permissionSubjects.GeneralProgramSettings,
      pageTitle: 'Settings Programs',
    },
  },
  {
    path: '/admin/families',
    name: 'admin-families',
    component: () => import('@/views/admin/families/families-list/FamiliesList.vue'),
    meta: {
      requiresSchoolAndProgram: false,
      resource: permissionSubjects.User,
      pageTitle: 'Families',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/families/:id',
    name: 'admin-family-view',
    component: () => import('@/views/admin/families/family-details/FamilyDetails.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      resource: permissionSubjects.User,
      navActiveLink: 'admin-families',
    },
    props: (route: Route) => ({ query: route.query }),
  },
  {
    path: '/admin/families-merger',
    name: 'admin-families-merger',
    component: () => import('@/views/admin/families/families-merger/FamiliesMerger.vue'),
    meta: {
      requiresSchoolAndProgram: true,
      action: 'update',
      resource: permissionSubjects.User,
      pageTitle: 'Merge Profiles',
      navActiveLink: 'admin-families',
    },
    props: route => ({ query: route.query }),
  },
]
export default admin
