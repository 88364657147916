export const instanceModules = {
  methods: {
    isSchoolModuleActive(moduleName) {
      const schoolData = JSON.parse(localStorage.getItem('schoolData') || '{}')
      return schoolData.config.modules.indexOf(moduleName) >= 0
    },
  },
}

export const _ = null
